import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import config from '@config'

import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import SummaryContainer from '@components/Summary/SummaryContainer'

import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'
import useTracking from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import useABTestValue from '@hooks/useABTestValue'
import {
  trackQuestionSummaryClickHandler,
  trackQuestionSummaryImpression,
} from './utils'
import { QuestionContainer, QuestionMessage } from './Question'
import QuestionSummaryRequestView from './ArticleQuestionRequestView'
import {
  SkeletonSeedValues,
  SkeletonView,
} from '@components/Summary/SummaryLoadingSkeleton'
import { ErrorView } from '@components/Summary/SummaryError'
import SvgIcon from '@components/SvgIcon'
import translate from '@i18n'
import QuestionSummaryResultView from './ArticleQuestionResultView'
import SummaryDisclaimer from '@components/Summary/SummaryDisclaimer'
import { skipToken, useQuery } from '@tanstack/react-query'
import { getAnswer, getQuestions, HTTP_METHOD_VALUES } from '@utils/chatbot'
import type { TQuestionData } from '@utils/chatbot/types'
import { WidgetIdValues } from '@utils/chatbot/types'
import type { QuestionSummaryProps } from './types'
import usePageMetadata from '@hooks/usePageMetadata'
import useModalOverlayPortal from '@hooks/useModalOverlayPortal'
import UserSnapInlineForm from '@components/UserSnap/InlineForm'
import ChatbotInput from '@components/Chatbot/ChatbotInput'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import useChatbot from '@hooks/useChatbot'
import useIsUserLoggedIn from '@hooks/useIsUserLoggedIn'
import useAuthentication from '@hooks/useAuthentication'
import useURLParam from '@hooks/useURLParam'
import useFeatureFlagsSkeleton from '@hooks/useFeatureFlagsSkeleton'

const {
  questionWidget: { moreInfoLink, userSnapFeedbackKey },
} = config

const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

const Title = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
      typography: {
        bodycopy: {
          small1: { bundledCSS: small1 },
        },
      },
    },
  }) => css`
    flex: 1;
    padding-right: ${spacing16};
    ${small1}
  `}
`

const IconPullRight = styled.div`
  flex: 0;
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
`

const SummaryWrapper = styled.div``

const TextPullLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

const StyledSecondaryCTAButton = styled(SecondaryCTAButton)`
  margin: 110px auto;
`

const StyledPrimaryCTAButton = styled(PrimaryCTAButton)`
  display: inline-block;
`

const AdditionQuestionsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing12};
    margin-bottom: ${spacing12};
  `}
`

const QuestionSummary: FunctionComponent<QuestionSummaryProps> = ({
  title = '',
  questions,
  question: initialQuestion,
  links,
  content,
}) => {
  const isChatbotEnabled = useABTestValue('showCUI') === 'show'
  const articleId = usePageMetadata().id ?? ''
  const theme = useTheme()
  const isLoggedIn = useIsUserLoggedIn()
  const { login } = useAuthentication()
  const { onQuestionClick, isLastQuestion } = useChatbot()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { portalNode, enterModalOverlay, exitModalOverlay, InPortal } =
    useModalOverlayPortal()

  const trackWidgetImpression = useTracking(trackQuestionSummaryImpression)
  const handleTrackingOnClick = useTracking(trackQuestionSummaryClickHandler)
  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackWidgetImpression,
  })
  const onLogin = () =>
    login({
      prefix: WidgetIdValues.ArticleQuestion,
      loginCase: 'email_only',
    })

  const [question, setQuestion] = useState<TQuestionData | undefined>(
    initialQuestion
  )

  const {
    data: questionsData,
    isLoading: questionsIsLoading,
    isError: questionsIsError,
    refetch: questionsRefetch,
  } = useQuery({
    queryKey: ['questionSummary.questions', articleId],
    queryFn: !!articleId
      ? async () => {
          const data = await getQuestions({
            widgetId: WidgetIdValues.ArticleQuestion,
            context: articleId,
          })()

          return data.questions
        }
      : skipToken,
    initialData: questions ? questions : undefined,
  })

  const {
    data: answersData,
    error: answersIsError,
    isLoading: answersIsLoading,
    isPending: answersIsPending,
    refetch: refetchAnswers,
  } = useQuery({
    queryKey: ['questionSummary.answer', articleId, question?.id],
    queryFn: !!question?.id // only fetch if question is set and no data is cached
      ? async () => {
          const data = await getAnswer({
            widgetId: WidgetIdValues.ArticleQuestion,
            context: articleId,
            questionId: question?.id,
          })()

          return { links: data.links, content: data.content }
        }
      : skipToken,
    initialData: links && content ? { links, content } : undefined,
  })

  const cancelCallback = useCallback(() => {
    setQuestion(undefined)

    if (!questionsIsLoading && questionsIsError) {
      // refetch questions if there was an error
      questionsRefetch()
    }
  }, [setQuestion, questionsIsLoading, questionsIsError, questionsRefetch])

  const retryCallback = useCallback(() => {
    if (questionsIsError) {
      questionsRefetch()
    } else if (answersIsError) {
      refetchAnswers()
    }
  }, [refetchAnswers, answersIsError, questionsIsError, questionsRefetch])

  const openChatbot = (question: TQuestionData) => {
    if (!isLastQuestion(question.id)) {
      onQuestionClick({
        context: articleId,
        isSuggestedQuestion: true,
        id: question.id,
        isCached: false,
        text: question.text,
      })
    }
  }

  const isLoading = answersIsLoading
  const isError = answersIsError
  const isSummaryLoaded = questionsData && !question && !isError // only show summary if no question is selected

  const isAnswerLoaded =
    question && answersData && !answersIsPending && !answersIsError

  const text = answersData?.content
    ?.map((contentItem) => contentItem.text)
    ?.join(' ')
  const {
    data: nextQuestionData,
    isLoading: nextQuestionIsLoading,
    isError: nextQuestionIsError,
  } = useQuery({
    queryKey: ['questionSummary.nextQuestion', articleId, text],
    queryFn:
      !!text && isLoggedIn && isChatbotEnabled
        ? async () => {
            const data = await getQuestions({
              method: HTTP_METHOD_VALUES.POST,
              body: {
                text,
                context: articleId,
              },
            })()

            return data.questions
          }
        : skipToken,
  })
  const isNextQuestionLoaded =
    nextQuestionData && !nextQuestionIsLoading && !nextQuestionIsError

  const disclaimer = useMemo(
    () => ({
      title: translate('sectionSummary.disclaimerText'),
      links: [
        {
          label: translate('sectionSummary.disclaimerMoreInfo'),
          link: moreInfoLink,
          target: '_blank',
          onClickHandler: (label: string) => handleTrackingOnClick({ label }),
        },
        {
          label: translate('sectionSummary.disclaimerFeedback'),
          target: '_blank',
          onClickHandler: (label: string) => {
            event?.preventDefault()
            handleTrackingOnClick({ label })
            setIsModalVisible(true)
            enterModalOverlay()
          },
        },
      ],
    }),
    [handleTrackingOnClick, enterModalOverlay, setIsModalVisible]
  )

  if (questionsIsError || questionsIsLoading) {
    return null
  }

  return (
    <SummaryWrapper id="question-widget">
      <SummaryContainer ref={viewportRef}>
        {portalNode && isModalVisible && (
          <InPortal node={portalNode}>
            <UserSnapInlineForm
              setIsVisible={(isVisible) => {
                if (!isVisible) {
                  exitModalOverlay()
                }
                enterModalOverlay()
              }}
              projectApiKey={userSnapFeedbackKey}
            />
          </InPortal>
        )}

        {isSummaryLoaded && (
          <QuestionSummaryRequestView title={title}>
            {questionsData.length ? (
              <QuestionContainer>
                {questionsData.map((data) => (
                  <QuestionMessage
                    onClick={() => {
                      setQuestion(data)
                      handleTrackingOnClick({ label: data.text })
                    }}
                    key={data.id}>
                    {data.text}
                  </QuestionMessage>
                ))}
                {isChatbotEnabled && (
                  <>
                    {(isLoggedIn && <ChatbotInput />) || (
                      <StyledPrimaryCTAButton
                        size="small"
                        onClick={() => onLogin()}>
                        {translate('chatbot.footer.loginButton')}
                      </StyledPrimaryCTAButton>
                    )}
                  </>
                )}
              </QuestionContainer>
            ) : null}
          </QuestionSummaryRequestView>
        )}

        {isAnswerLoaded && (
          <QuestionSummaryResultView
            articleId={articleId}
            title={translate('summary.result.title')}
            question={question}
            links={answersData.links}
            content={answersData.content}
            onCloseHandler={cancelCallback}>
            {isChatbotEnabled && (
              <AdditionQuestionsWrapper>
                {(isNextQuestionLoaded && (
                  <QuestionContainer>
                    {nextQuestionData.slice(0, 2).map((data) => (
                      <QuestionMessage
                        onClick={() => {
                          openChatbot(data)
                        }}
                        key={data.id}>
                        {data.text}
                      </QuestionMessage>
                    ))}
                  </QuestionContainer>
                )) ||
                  (isLoggedIn && (
                    <SkeletonView
                      seed={[
                        {
                          type: SkeletonSeedValues.QuestionButton,
                          multiplier: 2,
                        },
                      ]}
                    />
                  ))}
                {(isLoggedIn && <ChatbotInput />) || (
                  <StyledPrimaryCTAButton
                    size="small"
                    onClick={() => onLogin()}>
                    {translate('chatbot.footer.loginButton')}
                  </StyledPrimaryCTAButton>
                )}
              </AdditionQuestionsWrapper>
            )}

            {disclaimer && <SummaryDisclaimer {...disclaimer} />}
          </QuestionSummaryResultView>
        )}

        {isLoading && (
          <QuestionSummaryRequestView title={title}>
            <SkeletonView
              seed={[
                { type: SkeletonSeedValues.ParagraphNatural, multiplier: 7 },
              ]}
            />
          </QuestionSummaryRequestView>
        )}

        {isError && !isLoading && (
          <ErrorView>
            <Header>
              {question ? <Title>{question.text}</Title> : null}
              <IconPullRight onClick={cancelCallback}>
                <SvgIcon
                  iconName="xmark-large"
                  size={24}
                  color={theme.color.primary.primary01}
                />
              </IconPullRight>
            </Header>

            <TextPullLeft>
              {translate('sectionSummary.request.error')}
            </TextPullLeft>

            <StyledSecondaryCTAButton
              colorVariant="black"
              type="button"
              size="small"
              iconName="arrows-rotate"
              onClick={retryCallback}>
              {translate('sectionSummary.request.error.primaryButton')}
            </StyledSecondaryCTAButton>

            {disclaimer && <SummaryDisclaimer links={disclaimer.links} />}
          </ErrorView>
        )}
      </SummaryContainer>
    </SummaryWrapper>
  )
}

const ConditionallyEnabledQuestionSummary: FunctionComponent<
  QuestionSummaryProps
> = (props) =>
  [
    useFeatureFlagsSkeleton().isArticleQuestionsEnabled,
    useURLParam('eaq'),
  ].some((item) => item === true || item === 'true') ? (
    <QuestionSummary {...props} />
  ) : null

const widget = {
  kind: ['article-questions'],
  component: ConditionallyEnabledQuestionSummary,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
