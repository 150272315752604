import { ConfigProps } from '@config/types'

const config = ({
  publication,
  chaptersApiUrl,
  blickTvPlayerAssetUrlPrefix,
}: ConfigProps) => {
  const scriptVersion = '8.36.4'
  return {
    durationFormat: 'm:ss',
    durationFormatWithHours: 'H:mm:ss',
    shortVideoLengthThresholdInSeconds: 45,
    chaptersApiUrl: `${chaptersApiUrl}/tv/metadata`,
    videoMetadataUrl: 'https://cdn.jwplayer.com/v2/media/',
    playerScript: `${blickTvPlayerAssetUrlPrefix}${scriptVersion}/jwplayer.js`,
    licenseKey:
      publication === 'romandie'
        ? 'nrPnYbOlpaYY/k0W/9qAMQk2/jeFzlusfBljPHdofYvjFJpt'
        : 'hxV072x0+XH3HnmgeuFqmvOhGwYCCycR/EDcJpPYHVJU2Rds',
    seoPlayerId: publication === 'romandie' ? 'JZRCsdmR' : 'bnOE68px',
    chapters: {
      dvrWindow: 45,
      pollingIntervalInMilliseconds: 120000,
    },
    // transparent 1x1 poster to improve LCP score
    transparentPoster:
      'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEAAAAALAAAAAABAAEAAAIBAAA=',
  } as const satisfies Record<string, unknown>
}

export default config
