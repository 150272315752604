import { ConfigProps } from '@config/types'

const config = ({ staticAssetPageUrl, staticPageUrlSuffix }: ConfigProps) =>
  ({
    facebookAppId: '198633050385',
    facebookStaticPageUrl: `${staticAssetPageUrl}/facebook${staticPageUrlSuffix}?url=`,
    twitterEmbedStaticPageUrl: `${staticAssetPageUrl}/twitter${staticPageUrlSuffix}?url=`,
    instagramEmbedStaticPageUrl: `${staticAssetPageUrl}/instagram${staticPageUrlSuffix}?url=`,
    tiktokEmbedStaticPageUrl: `${staticAssetPageUrl}/tiktok${staticPageUrlSuffix}?url=`,
    blueSkyEmbedStaticPageUrl: `${staticAssetPageUrl}/bluesky${staticPageUrlSuffix}?url=`,
    blickplusTitleAddition: '(B+)',
    share: {
      utmMedium: 'social',
      utmCampaign: 'share-button',
      types: {
        facebook: {
          utmSource: 'facebook',
          iconName: 'facebook-plain',
        },
        twitter: {
          utmSource: 'twitter',
          iconName: 'twitter-plain',
        },
        whatsapp: {
          utmSource: 'whatsapp',
          iconName: 'whatsapp-plain',
        },
        mail: {
          utmSource: 'transactional',
          utmMedium: 'email',
          iconName: 'mail-plain',
        },
        copy: {
          utmSource: 'copy-to-clipboard',
          iconName: 'copy-plain',
        },
        print: {
          iconName: 'print-plain',
        },
        pluslinkshare: {
          utmSource: 'blickplus-gift',
          iconName: 'gift',
        },
        headphones: {
          utmSource: 'text-to-speech',
          iconName: 'headphones',
        },
        close: {
          utmSource: '',
          iconName: 'xmark-large',
        },
      },
    },
  }) as const satisfies Record<string, unknown>

export default config
