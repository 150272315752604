import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import config from '@config'
import { SelectBoxProps } from './types'
import { writeToLocalStorage } from './utils'

const {
  abTest: { windowKey, featureFlagsEntries },
} = config

export type FeatureFlagVariantNames = keyof typeof featureFlagsEntries

const getSelectBoxValue = (variantName: FeatureFlagVariantNames): any => {
  try {
    const featureFlagStorageInfo = (JSON.parse(
      localStorage.getItem(windowKey) as any
    ) ?? {}) as Record<FeatureFlagVariantNames, unknown>

    if (featureFlagStorageInfo.hasOwnProperty(variantName)) {
      return featureFlagStorageInfo[variantName]
    } else {
      return ''
    }
  } catch (err) {
    //! nothing we can do!
    console.error(err)
  }

  return ''
}

const synchronizeSelectBoxLocalStorage = ({
  variantName,
  selectedValue,
}: {
  variantName: FeatureFlagVariantNames
  selectedValue: (typeof featureFlagsEntries)[FeatureFlagVariantNames]['values'][number]['name']
}) => {
  try {
    const featureFlagStorageInfo =
      JSON.parse(localStorage.getItem(windowKey) as any) ?? {}

    if ((selectedValue as unknown) !== '') {
      featureFlagStorageInfo[variantName] = selectedValue
    } else {
      delete featureFlagStorageInfo[variantName]
    }

    writeToLocalStorage(featureFlagStorageInfo)
  } catch (err) {
    //! nothing we can do!
    console.error(err)
  }
}

const SelectBoxWrapper = styled.div``

const StyledSelectBox = styled.select`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopy },
        },
      },
      spacing: { spacing2, spacing4 },
    },
  }) => css`
    max-width: 100%;
    padding: ${spacing2};
    border: 2px solid ${primary01Color};
    border-radius: ${spacing4};

    ${small2BodyCopy}
  `}
`

const StyledSelectBoxOption = styled.option``

const SelectBox: FunctionComponent<SelectBoxProps> = ({
  variantName,
  updateKey,
  children,
}) => {
  const [selectedValue, setSelectedValue] = useState<any>('')

  const onChange = useCallback<(event: ChangeEvent<HTMLSelectElement>) => void>(
    (event) => {
      const newValue = featureFlagsEntries[variantName]?.values?.find(
        (entry) => `${entry.name}` === event.currentTarget.value
      )?.name
      setSelectedValue(newValue)
    },
    [variantName]
  )

  useEffect(() => {
    setSelectedValue(getSelectBoxValue(variantName))
  }, [variantName, updateKey])

  useEffect(() => {
    synchronizeSelectBoxLocalStorage({ variantName, selectedValue })
  }, [variantName, selectedValue])

  return (
    <SelectBoxWrapper>
      <StyledSelectBox onChange={onChange} value={`${selectedValue}`}>
        <StyledSelectBoxOption value="">
          No Manual Override
        </StyledSelectBoxOption>
        {children.map(({ name, description }) => (
          <StyledSelectBoxOption
            key={`${variantName}.${name}`}
            value={name as string | number}>
            {description ?? name}
          </StyledSelectBoxOption>
        ))}
      </StyledSelectBox>
    </SelectBoxWrapper>
  )
}

export default SelectBox
